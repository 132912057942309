const newsRouter = {
  route: null,
  name: null,
  title: '资讯信息',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/news/', // 文件路径
  order: 3,
  inNav: true,
  permission: ['资讯列表'],
  children: [
    {
      title: '资讯列表',
      type: 'view',
      name: 'news',
      route: '/news/list',
      filePath: 'view/news/list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli'
    }
  ],
}

export default newsRouter
